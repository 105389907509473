<div class="clearfix page-cst-st-table">
  <div class="tc-table-page-header" tc-sticky-header fxLayoutAlign="space-between center">
    <div fxFlex>
      <div class="mat-headline-6">TradeCafé - Buyer Grouping</div>
      <tc-breadcrumbsv2 [activeView]="'Default View'"></tc-breadcrumbsv2>
    </div>
    <button mat-raised-button type="button" (click)="addNewGroup()" color="add">
      <mat-icon class="fa fa-plus"></mat-icon> Add New Group
    </button>
  </div>
  <div class="buyers-groups" fxLayout="column" fxLayoutAlign="center stretch">

    @for (group of groups; track group) {
      <div fxFlex>
        <tc-buyers-groups-item [group]="group" [buyers]="buyers" [buyersMap]="buyersMap" (groupDeleted)="deleteGroup($event)"
        (groupUpdated)="updateGroup($event)"></tc-buyers-groups-item>
      </div>
    }

    @if (loading) {
      @for (_ of [1, 2]; track _) {
        <div class="placeholder text-center" gdColumns="repeat(1, minmax(0, 1fr))" gdGap="5px"
          fxLayout="column" fxLayoutAlign="center">
          <div fxFlex class="skeleton-loader circle"></div>
          <div fxFlex class="skeleton-loader tiny-line"></div>
          <div fxFlex class="skeleton-loader thin-line"></div>
        </div>
      }
    }

    @if (!groups?.length && !loading) {
      <div class="placeholder text-center">
        <mat-icon class="fa fa-users groups-icon"></mat-icon>
        <h4>You do not have any saved buyers groups</h4>
        <span>Please click the 'Add New Group' button to the top right to create your first buyers group.</span>
      </div>
    }

  </div>
</div>
