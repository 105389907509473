import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AccountObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { BuyersGroup, BuyersGroupApiService } from '../buyers-groups-item/buyers-groups.service'

@Component({
  selector: 'tc-add-buyers-group',
  templateUrl: './add-buyers-group.component.html',
})
export class AddBuyersGroupComponent extends OnDestroyMixin {
  formGroup: FormGroup
  buyers: DeepReadonly<AccountObject>[]
  groups: BuyersGroup[]

  constructor(
    private buyersGroupService: BuyersGroupApiService,
    private toaster: ToasterService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { buyers: DeepReadonly<AccountObject>[], groups: BuyersGroup[] },
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddBuyersGroupComponent>,
  ) {
    super()
    this.formGroup = this.formBuilder.group({
      buyers: [null, Validators.required],
      name: [null, Validators.required],
    })
    this.buyers = this.dialogData.buyers
    this.groups = this.dialogData.groups
  }

  cancel() {
    this.dialogRef.close()
  }

  async save() {
    if (this.formGroup.valid) {
      const group = this.formGroup.value
      try {
        const { data } = await this.buyersGroupService.create(group)
        this.toaster.success(
          `Buyer Group "${group.name}" was created successfully.`,
        )
        this.groups.unshift(data)
        this.dialogRef.close(this.formGroup.value)
      } catch {
        this.toaster.error(`Unable to create Buyer Group "${group.name}".`)
      }
    }
  }
}
